<template>
  <v-main>
    <h4>Autocompletes & Selects</h4>
    <!--
      Props
      items: "Lista de elementos que permitira seleccionar"
      item-text: "Selecciona el elemento del objeto que tendra el texto a mostrar"
      item-value: "Selecciona el elemento del objeto que tendra el valor a almacenar"
      outlined: "Determina el estilo con el que se mostrara, outlined es utilizado por defecto",
      dense: "Hace que el campo sea mas pequeño, se usa por defecto"
      chips: "Se usa para el autocomplete multiple para que muestre distintos valores en burbujas mas legible para el usuario"
      small-chips: "Se usa para hacer mas pequeñas los chips"
      label: "Es el titulo visible del campo"
      -->
    El autocomplete se utiliza cuando la lista tiene mas de 5 registros
    <v-autocomplete
      v-model="form.value"
      :items="items"
      item-text="title"
      item-value="id"
      outlined
      dense
      label="Este es el autocomplete con unico valor"
    ></v-autocomplete>
    <code>{{ form.value }} TypeOf {{ typeof form.value }}</code>
    <v-autocomplete
      v-model="form.valueMultiple"
      :items="items"
      item-text="title"
      item-value="id"
      outlined
      dense
      chips
      small-chips
      label="Este es el autocomplete con Multiple valor"
      multiple
    ></v-autocomplete>
    <code>
      {{ form.valueMultiple }} TypeOf
      {{ typeof form.valueMultiple }}
    </code>
    <br />
    El select se utiliza cuando la lista tiene 5 o menos registros
    <v-select
      v-model="form.selectValue"
      :items="items"
      item-text="title"
      item-value="id"
      outlined
      dense
      label="Este es el select con unico valor"
    ></v-select>
    <code>
      {{ form.selectValue }} TypeOf
      {{ typeof form.selectValue }}
    </code>
    <v-select
      v-model="form.selectValueMultiple"
      :items="items"
      item-text="title"
      item-value="id"
      outlined
      dense
      chips
      small-chips
      label="Este es el select con Multiple valor"
      multiple
    ></v-select>
    <code>
      {{ form.selectValueMultiple }} TypeOf
      {{ typeof form.selectValueMultiple }}
    </code>

    <h4>Input</h4>
    <v-text-field
      v-model="form.valueInput"
      label="Label: input generico"
      outlined
      dense
    ></v-text-field>
    <v-text-field
      v-model="form.valueInput"
      label="Label: input generico sin espacio para el mensaje de validaciones"
      outlined
      dense
      hide-details
    ></v-text-field>
    <v-text-field
      v-model="form.valueInput"
      label="Label: input con tooltip en icono"
      outlined
      dense
    >
      <template v-slot:append>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
          </template>
          Aqui viene el tooltip, este es un tooltip de prueba.
        </v-tooltip>
      </template>
    </v-text-field>
    <h4>Textarea</h4>
    <v-textarea
      v-model="form.valueInput"
      label="Label: input generico sin espacio para el mensaje de validaciones"
      outlined
      dense
      hide-details
    ></v-textarea>
    <h4>Checkbox & Radio button</h4>
    <v-checkbox
      v-model="form.checkboxArray"
      label="Checkbox con Value"
      value="ValueAlmacenado"
      hide-details
    ></v-checkbox>
    <v-checkbox
      v-model="form.checkboxArray"
      label="Checkbox con Value"
      value="ValueAlmacenado 1"
      hide-details
    ></v-checkbox>
    <v-checkbox
      v-model="form.checkboxArray"
      label="Checkbox con Value"
      value="ValueAlmacenado 2"
      hide-details
    ></v-checkbox>
    <code>
      {{ form.checkboxArray }} TypeOf
      {{ typeof form.checkboxArray }}
    </code>
    <v-checkbox
      v-model="form.checkbox"
      label="Checkbox Booleano"
      hide-details
    ></v-checkbox>
    <code>
      {{ form.checkbox }} TypeOf
      {{ typeof form.checkbox }}
    </code>
    <v-row>
      <v-checkbox
        v-model="form.checkboxHorizontal"
        value="Horizontal 1"
        label="Checkbox Horizontal"
        hide-details
      ></v-checkbox>
      <v-checkbox
        v-model="form.checkboxHorizontal"
        value="Horizontal 2"
        label="Checkbox Horizontal"
        hide-details
      ></v-checkbox>
      <v-checkbox
        v-model="form.checkboxHorizontal"
        value="Horizontal 3"
        label="Checkbox Horizontal"
        hide-details
      ></v-checkbox>
    </v-row>
    <code>
      {{ form.checkboxHorizontal }} TypeOf
      {{ typeof form.checkboxHorizontal }}
    </code>
    <v-radio-group v-model="form.radioButton">
      <v-radio
        v-for="n in 3"
        :key="n"
        :label="`Radio ${n}`"
        :value="n"
      ></v-radio>
    </v-radio-group>
    <code>
      {{ form.radioButton }} TypeOf
      {{ typeof form.radioButton }}
    </code>
    <v-radio-group row v-model="form.radioButton">
      <v-radio
        v-for="n in 3"
        :key="n"
        :label="`Radio ${n}`"
        :value="n"
      ></v-radio>
    </v-radio-group>
    <code>
      {{ form.radioButton }} TypeOf
      {{ typeof form.radioButton }}
    </code>
    <h4>Button Edit and Delete List</h4>
    <a class="btn btn-icon btn-light btn-hover-danger" @click="methodToCall()">
      <span class="svg-icon svg-icon-md svg-icon-danger">
        <v-icon color="red">
          mdi-delete
        </v-icon>
      </span>
    </a>
    <a class="btn btn-icon btn-light btn-hover-warning" @click="methodToCall()">
      <span class="svg-icon svg-icon-md svg-icon-warning">
        <v-icon color="orange">
          mdi-pencil
        </v-icon>
      </span>
    </a>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      form: {
        value: "",
        valueMultiple: [],
        selectValue: "",
        selectValueMultiple: [],
        valueInput: "",
        checkboxArray: [],
        checkbox: false,
        checkboxHorizontal: [],
        radioButton: null,
      },
      items: [
        { id: 1, title: "prueba 1" },
        { id: 2, title: "prueba 2" },
        { id: 3, title: "prueba 3" },
        { id: 4, title: "prueba 4" },
      ],
    };
  },
};
</script>

<style></style>
