<template>
  <div>
    <Buttons
      :textCancel="$t('GENERAL.CANCEL')"
      v-on:cancel="btnCancel"
      :textSave="$t('GENERAL.SAVE')"
      v-on:save="btnSave"
      :save_loading="save_loading"
      :btnSaveNew="true"
      :save_new_loading="save_new_loading"
      :textSaveNew="$t('GENERAL.SAVE_AND_NEW')"
      v-on:save-new="btnSaveNew"
      :btnDuplicate="true"
      :duplicate_loading="duplicate_loading"
      :textDuplicate="$t('GENERAL.DUPLICATE')"
      v-on:duplicate="btnDuplicate"
      :btnDelete="true"
      :delete_loading="delete_loading"
      :textDelete="$t('GENERAL.DELETE')"
      v-on:delete="btnDelete"
    />
    <v-card elevation="2">
      <v-card-title> Example Title </v-card-title>
      <v-card-text>
        <validation-observer ref="observer">
          <div v-for="(item, index) in list" :key="index">
            <v-row gutters="2" class="mb-0 pb-0">
              <v-col cols="5" md="2" sm="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Duration"
                  :rules="{
                    required: true,
                    min: 0,
                    max: 50,
                  }"
                >
                  <v-text-field
                    v-model="item.duration"
                    outlined
                    dense
                    type="number"
                    label="Duration"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="5" md="3" sm="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Duration Type"
                  :rules="{
                    required: true,
                  }"
                >
                  <v-select
                    v-model="item.durationTypeId"
                    outlined
                    dense
                    item-value="id"
                    label="Duration Type"
                    :items="durationTypes"
                    :error-messages="errors"
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col cols="5" md="2" sm="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Amount"
                  :rules="{
                    required: true,
                  }"
                >
                  <v-text-field
                    v-model="item.amount"
                    outlined
                    dense
                    :error-messages="errors"
                  >
                    <template
                      :slot="campus.before !== 1 ? 'append' : 'prepend-inner'"
                    >
                      {{ campus.symbol }}
                    </template>
                  </v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="5" md="3" sm="4">
                <v-menu
                  v-model="item.menu"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('GENERAL.DATE')"
                      rules="required"
                    >
                      <v-text-field
                        :value="item.date_formated"
                        clearable
                        outlined
                        dense
                        :label="$t('GENERAL.DATE')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="item.date = null"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="item.date"
                    :first-day-of-week="campus.calendar_format"
                    @change="
                      item.menu = false;
                      item.date = $moment(item.date).format('YYYY-MM-DD');
                      item.date_formated = $moment(item.date).format(
                        campus.date_format
                      );
                    "
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="2" md="1" sm="1">
                <a
                  class="btn btn-icon btn-light btn-hover-danger"
                  @click="deleteRow(index)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <v-icon color="red">
                      mdi-delete
                    </v-icon>
                  </span>
                </a>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="12" md="12">
              <v-btn depressed color="primary" @click="addItem">Add new</v-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
    </v-card>
    <v-card elevation="2" class="mt-10">
      <v-card-title> Pricing Template </v-card-title>
      <v-card-text>
        <PricingTemplate
          :productPricingTemplateId="productPricingTemplateId"
          :availableOptions="[1, 2, 3, 4]"
          ref="pricingTemplate"
        ></PricingTemplate>
      </v-card-text>
    </v-card>
    <v-card elevation="2" class="mt-2">
      <v-card-title> Products Tree </v-card-title>
      <v-card-text>
        <ProductsTreeView
          :data="productsTreeData"
          is-required
          :input.sync="productsTreeSelected"
          name="Example"
        ></ProductsTreeView>
        <code>{{ productsTreeSelected }}</code>
      </v-card-text>
    </v-card>
    <v-card elevation="2" class="mt-2">
      <v-card-title> Country Select </v-card-title>
      <v-card-text>
        <CountrySelect :form-data.sync="countriesSelected"></CountrySelect>
        <code>{{ countriesSelected }}</code>
      </v-card-text>
    </v-card>
    <v-card elevation="2" class="mt-2">
      <v-card-title> Markets and Countries Selector </v-card-title>
      <v-card-text>
        <MarketsCountriesSelector
          :input.sync="marketsCountriesSelected"
          is-required
        ></MarketsCountriesSelector>
        <code>{{ marketsCountriesSelected }}</code>
      </v-card-text>
    </v-card>
    <v-card elevation="2" class="mt-2">
      <v-card-title> Example Form </v-card-title>
      <v-card-text>
        <ExampleComponentsVuetify></ExampleComponentsVuetify>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12" md="4">
        <Translation
          ref="translationComponent"
          :fields="dataTranslation"
          model="campus"
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-btn
          depressed
          color="primary"
          class="ml-3"
          @click="getDataTranslation"
        >
          Get Translate
        </v-btn>
      </v-col>
    </v-row>
    <v-card elevation="2" class="mt-2">
      <v-card-title> DatePicker Example </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" class="mt-0 mb-0 pt-0 pb-0">
            <DatePicker
              :date="date1"
              :is-required="true"
              name="From"
              :input.sync="date1"
            />
          </v-col>
          <v-col cols="12" md="6" class="mt-0 mb-0 pt-0 pb-0">
            <DatePicker
              :date="date2"
              :is-required="true"
              name="To"
              :input.sync="date2"
              :date-min="date1"
            />
          </v-col>
        </v-row>
        <code> {{ date1 }} - {{ date2 }} </code>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
//import Vue from "vue";
//import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import PricingTemplate from "@/view/pages/configure/pricing/components/PricingTemplate";
import ProductsTreeView from "@/view/content/components/ProductsTreeView";
import CountrySelect from "@/view/content/components/CountrySelect";
import Buttons from "@/view/content/components/Buttons";
import MarketsCountriesSelector from "@/view/content/components/MarketsCountriesSelector";
import ExampleComponentsVuetify from "@/view/content/components/ExampleComponentsVuetify";
import Translation from "@/view/content/components/translation/Index";
import DatePicker from "@/view/content/components/DatePicker";

export default {
  data() {
    return {
      dataTranslation: [
        {
          title: this.$i18n.t("GENERAL.NAME"),
          type: "name",
        },
        {
          title: this.$i18n.t("GENERAL.DESCRIPTION"),
          type: "description",
        },
        {
          title: "TEST TEST",
          type: "description2",
        },
      ],
      dataTranslation2: [
        {
          languageId: 9,
          fields: [
            { title: "Name", value: "qweqweqwe" },
            { title: "Description", value: "qweqweqweqwe" },
          ],
        },
        {
          languageId: 16,
          fields: [
            { title: "Name", value: "qweqweqwe" },
            { title: "Description", value: "qweqweqweqwe" },
          ],
        },
        {
          languageId: 6,
          fields: [
            { title: "Name", value: "qweqweqwe" },
            { title: "Description", value: "qweqweqweqwe" },
          ],
        },
        {
          languageId: 5,
          fields: [
            { title: "Name", value: "qweqweqwe" },
            { title: "Description", value: "qweqweqweqwe" },
          ],
        },
        {
          languageId: 8,
          fields: [
            { title: "Name", value: "qweqweqwe" },
            { title: "Description", value: "qweqweqweqwe" },
          ],
        },
      ],
      class_btn_save: ["mr-3"],
      class_btn_duplicate: ["mr-3"],
      class_btn_delete: ["mr-3"],
      cancel_loading: false,
      save_loading: false,
      save_new_loading: false,
      duplicate_loading: false,
      delete_loading: false,
      list: [],
      productPricingTemplateId: 631821,
      countriesSelected: [],
      productsTreeData: [
        {
          productId: 1,
          name: "text",
        },
      ],
      productsTreeSelected: [],
      marketsCountriesSelected: [],
      durationTypes: [
        {
          id: 2,
          text: "Day",
        },
        {
          id: 3,
          text: "Week",
        },
        {
          id: 4,
          text: "Month",
        },
      ],
      translations: [],
      date1: "",
      date2: "",
    };
  },
  name: "basictemplate",
  components: {
    ValidationObserver,
    ValidationProvider,
    PricingTemplate,
    ProductsTreeView,
    CountrySelect,
    MarketsCountriesSelector,
    ExampleComponentsVuetify,
    Buttons,
    Translation,
    DatePicker,
  },
  computed: {
    ...mapGetters(["DurationTypes", "campus"]),
  },
  methods: {
    // ---------------------
    // ------- BUTTONS -----
    // ---------------------
    btnCancel() {
      this.cancel_loading = true;
      setTimeout(() => {
        this.cancel_loading = false;
        this.$bvToast.toast(this.$t("GENERAL.CANCEL"), {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }, 2000);
    },

    btnSave() {
      this.save_loading = true;
      setTimeout(() => {
        this.save_loading = false;
        this.$bvToast.toast(this.$t("GENERAL.SAVE"), {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }, 2000);
    },

    btnSaveNew() {
      this.save_new_loading = true;
      setTimeout(() => {
        this.save_new_loading = false;
        this.$bvToast.toast(this.$t("GENERAL.SAVE_AND_NEW"), {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }, 2000);
    },

    btnDuplicate() {
      this.duplicate_loading = true;
      setTimeout(() => {
        this.duplicate_loading = false;
        this.$bvToast.toast(this.$t("GENERAL.DUPLICATE"), {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }, 2000);
    },

    btnDelete() {
      this.delete_loading = true;
      setTimeout(() => {
        this.delete_loading = false;
        this.$bvToast.toast(this.$t("GENERAL.DELETE"), {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }, 2000);
    },
    // ---------------------
    // ---------------------
    addItem() {
      this.list.push({
        duration: null,
        durationTypeId: null,
        amount: null,
        date: null,
        date_formated: null,
      });
    },
    async save() {
      if (await this.$refs.observer.validate()) {
        this.save_loading = true;

        let productPricingTemplate = false;
        //result = await this.$refs.pricingTemplate.storeOrUpdate();
        await this.$refs.pricingTemplate
          .validateAndGetData()
          .then(async data => {
            productPricingTemplate = data;

            //Here you can add all the following methods required to save more data.
            productPricingTemplate;
            if (productPricingTemplate) {
              if (productPricingTemplate.productPricingTemplateId === 0) {
                await this.$refs.pricingTemplate.store(productPricingTemplate);
              } else {
                await this.$refs.pricingTemplate.save(productPricingTemplate);
              }
            }
          });

        this.save_loading = false;
      } else {
        this.$bvToast.toast(this.$t("GENERAL.PLEASE_VERIFY_FIELDS"), {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    deleteRow(index) {
      this.list.splice(index, 1);
    },
    getDataTranslation() {
      const exampleJson = ["example 1", "example 2", "example 3"];
      this.$refs.translationComponent.getTranslations(10, exampleJson);
    },
  },
};
</script>
